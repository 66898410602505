<!-- <script lang="ts" setup>
const nuxtApp = useNuxtApp();

const loading = ref(false);

function start() {
  loading.value = true;
}

function finish() {
  loading.value = false;
}

nuxtApp.hook("page:start", start);
nuxtApp.hook("page:finish", finish);
nuxtApp.hook("vue:error", finish);
onBeforeUnmount(finish);
</script> -->

<template>
  <v-progress-circular
    :size="150"
    :width="7"
    color="primary"
    indeterminate
  >
    <Logo class="logo" />
  </v-progress-circular>
</template>

<style lang="scss" scoped>
.logo {
  // position: absolute;
  height: 64px;
}
</style>
